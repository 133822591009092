import { CellLayout, DashboardView, dashboard } from "@fscrypto/domain/dashboard";
import { useEntity, useStoreWithDynamicSelector } from "@fscrypto/state-management";
import { useMemo } from "react";
import { dashboardFactory } from "../state/dashboard";

export const useLayout = (
  cellId: string,
  dashboardId: string,
  dashboardView: DashboardView,
  isMobile: boolean = false,
) => {
  const db = useEntity(dashboardFactory, dashboardId);
  const val = useStoreWithDynamicSelector(db?.store, (s) => {
    const config = dashboardView === "published" ? s.publishedConfig : s.draftConfig;
    return dashboard.findChildLayoutsFromCellId(cellId, isMobile)(config);
  });
  return useMemo(() => (val ?? []) as CellLayout[], [val]);
};
