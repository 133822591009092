import { CustomIcon, TitleOnly } from "../../config-elements/config-elements";

export const TabLayoutCellOptions = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  return (
    <div className="flex h-full w-full flex-col gap-y-4 p-3 ">
      <TitleOnly cellId={cellId} dashboardId={dashboardId} label={"Tab Title"} />
      <CustomIcon cellId={cellId} dashboardId={dashboardId} />
    </div>
  );
};
