import { visualization } from "@fscrypto/domain";
import { Options } from "@fscrypto/domain/visualization/v3";
import { Text } from "@fscrypto/ui";
import { Settings } from "@fscrypto/viz-2";
import { tracking } from "~/utils/tracking";
import { useVisualizationCellSettings } from "../../../hooks/visualization-cells/useVisualizationCellSettings";
import { ConfigAccordion, ConfigAccordionItem, CustomIcon } from "../../config-elements/config-elements";

const onTabClick = (tab: "fields" | "format" | "settings") => {
  tracking(`editor_chart_edit_${tab}`, "Dashboard Beta");
};

const onHighChartsApiClick = () => {
  tracking("editor_chart_edit_settings_highcharts", "Dashboard Beta");
};

export const VisualizationCellOptions = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  return (
    <div className="flex flex-col gap-y-4 p-3 ">
      <Text size="sm" color={"muted"}>
        This panel controls the configuration of a visualization
      </Text>
      <CustomIcon cellId={cellId} dashboardId={dashboardId} />
      <VisualizationSettings cellId={cellId} dashboardId={dashboardId} />
    </div>
  );
};

const VisualizationSettings = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  const { viz, options, inputs, fields, dataKeys, onChange, onOptionsChange, updateTitle } =
    useVisualizationCellSettings(cellId, dashboardId);

  if (!viz || !options || !inputs || !dataKeys) return null;

  return (
    <ConfigAccordion value={["Visualization Settings"]}>
      <ConfigAccordionItem label="Visualization Settings">
        <Settings
          fields={fields}
          viz={viz}
          dataKeys={dataKeys}
          onChange={(d) => {
            const title = d.config.options.title?.text ?? "";
            updateTitle(title);
            onChange(d);
          }}
          options={options as Options}
          onOptionsChange={(c) => {
            const title = c.title?.text ?? "";
            updateTitle(title);
            onOptionsChange(c as visualization.v3.Options);
          }}
          onTabClick={onTabClick}
          onHighChartsApiClick={onHighChartsApiClick}
        />
      </ConfigAccordionItem>
    </ConfigAccordion>
  );
};
