import { ParentSize } from "@fscrypto/viz";
import { motion } from "framer-motion";
export const DashboardGridContainer = ({ children }: { children: (width: number) => JSX.Element }) => {
  return (
    <ParentSize>
      {({ width }) => {
        return (
          <motion.div
            className="size-full flex-1"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            {children(width)}
          </motion.div>
        );
      }}
    </ParentSize>
  );
};
