import { DashboardView } from "@fscrypto/domain/dashboard";
import clsx from "clsx";
import { DynamicIcon } from "~/shared/components/dynamic-icon";
import { useDashboardCellContent } from "../../../hooks/useDashboardCellContent";
import { useDashboardCellStyles } from "../../../hooks/useDashboardCellStyles";
import { ActiveCellListener } from "../../active-cell/active-cell-listener";
import { BackgroundContainer, BorderContainer, Description, Title } from "../../content-elements/content-elements";

export const HeadingCellContent = ({
  cellId,
  dashboardId,
  dashboardView,
  isMobile,
}: {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
  isMobile: boolean;
}) => {
  return (
    <ActiveCellListener cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} isMobile={isMobile}>
      <BorderContainer cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView}>
        <BackgroundContainer cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView}>
          <div className={clsx("size-full flex items-center")}>
            <TitleDescription cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} />
          </div>
        </BackgroundContainer>
      </BorderContainer>
    </ActiveCellListener>
  );
};

export const TitleDescription = ({
  cellId,
  dashboardId,
  dashboardView,
}: {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
}) => {
  const [{ title, description, showDescription, showTitle, icon, iconColor, titleSize, titleAlign }] =
    useDashboardCellContent(cellId, dashboardId, "heading", dashboardView);
  const [cellStyle] = useDashboardCellStyles(cellId, dashboardId, "base", dashboardView);
  return (
    <div
      className={clsx("size-full flex flex-col items-start justify-center p-3", {
        "items-center": titleAlign === "center",
        "items-start": titleAlign === "start" || !titleAlign,
        "items-end": titleAlign === "end",
      })}
    >
      <div className="flex items-center gap-x-2">
        {icon && (
          <DynamicIcon
            name={icon}
            className={clsx({
              "size-5": titleSize === "lg",
              "size-6": titleSize === "xl" || titleSize === "2xl",
              "size-8": titleSize === "3xl",
            })}
            style={{ color: iconColor }}
          />
        )}
        {showTitle && <Title title={title} titleSize={titleSize ?? titleSize} titleColor={cellStyle?.titleColor} />}
      </div>

      {showDescription && (
        <div className="flex items-center gap-x-2">
          <Description description={description} descriptionColor={cellStyle?.descriptionColor} />
        </div>
      )}
    </div>
  );
};
