import type { DashboardView } from "@fscrypto/domain/dashboard";
import { ProfilePublic } from "@fscrypto/domain/profile";
import { Avatar, Button, Tag, Text, TwitterIcon } from "@fscrypto/ui";
import { Link } from "@remix-run/react";
import clsx from "clsx";
import { HeartIcon, RefreshCcwIcon } from "lucide-react";
import { DateTime } from "luxon";
import { $path } from "remix-routes";
import MarketingAuthModal from "~/features/app-shell/marketing/marketing-auth-modal";
import { CommentsPanel, CommentsPanelToggleButton } from "~/features/comments/ui/commentsPanel";
import { useCurrentUser } from "~/features/current-user";
import { useEmbedded } from "~/hooks/useEmbedded";
import { tracking } from "~/utils/tracking";
import { useDashboardCellContent } from "../../../hooks/useDashboardCellContent";
import { useDashboardCellStyles } from "../../../hooks/useDashboardCellStyles";
import { useDashboardSettings } from "../../../hooks/useDashboardSettings";
import { ActiveCellListener } from "../../active-cell/active-cell-listener";
import { BackgroundContainer, BorderContainer, Title } from "../../content-elements/content-elements";
import { QuestLink } from "./quest-link";
import { type UITag, useTags, SingleTagRow } from "~/features/tags";
import { useFeatureFlag } from "~/state/feature-flags";
import { useState } from "react";

export const HeaderCellContent = ({
  cellId,
  dashboardId,
  dashboardView,
  isMobile,
}: {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
  isMobile: boolean;
}) => {
  const { owner, publishedAt } = useDashboardSettings(dashboardId);
  const [isCommentsOpen, setIsCommentsOpen] = useState(false);
  const { linkTarget } = useEmbedded();
  const { tags } = useTags("dashboard", dashboardId, { skipFetch: typeof window === "undefined" });
  const isTagsEnabled = useFeatureFlag("dashboard-tags");

  return (
    <>
      <ActiveCellListener cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} hideControls>
        <BorderContainer cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView}>
          <BackgroundContainer cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView}>
            {isMobile ? (
              <HeaderCellMobileContent
                cellId={cellId}
                dashboardId={dashboardId}
                dashboardView={dashboardView}
                owner={owner}
                setIsCommentsOpen={setIsCommentsOpen}
                tags={isTagsEnabled ? tags : undefined}
              />
            ) : (
              <DesktopHeader
                cellId={cellId}
                dashboardId={dashboardId}
                dashboardView={dashboardView}
                owner={owner}
                linkTarget={linkTarget}
                publishedAt={publishedAt}
                setIsCommentsOpen={setIsCommentsOpen}
                tags={isTagsEnabled ? tags : undefined}
              />
            )}
          </BackgroundContainer>
        </BorderContainer>
      </ActiveCellListener>
      <CommentsPanel
        isOpen={isCommentsOpen}
        onClose={() => setIsCommentsOpen(false)}
        resourceId={dashboardId}
        resourceType="dashboard"
      />
    </>
  );
};

const DesktopHeader = ({
  cellId,
  dashboardId,
  dashboardView,
  owner,
  linkTarget,
  publishedAt,
  setIsCommentsOpen,
  tags,
}: {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
  owner?: ProfilePublic;
  linkTarget: string;
  publishedAt?: Date | null;
  setIsCommentsOpen: (open: boolean) => void;
  tags?: UITag[];
}) => {
  return (
    <div className="flex items-center justify-between">
      <div className={clsx("flex h-full w-full flex-col max-w-[calc(100vw - 800px)]")}>
        <HeaderTitle cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} />
        <div className="flex items-center gap-x-2 px-2 pb-2 pt-1 overflow-x-hidden">
          <Link to={owner?.profileUrl ?? ""} target={linkTarget} className="flex items-center gap-x-2">
            <Avatar className="" size={"sm"} src={owner?.avatarUrl ?? ""} />
            <Text size="sm" weight={"semi"} className="whitespace-nowrap">
              {owner?.name}
            </Text>
          </Link>
          <Contributors dashboardId={dashboardId} dashboardView={dashboardView} />
          {publishedAt && (
            <>
              <span className="text-muted-foreground text-sm">|</span>
              <Text size="xs" weight={"semi"} color="muted" className="whitespace-nowrap">
                {DateTime.fromJSDate(publishedAt).toFormat("dd LLL yyyy")}
              </Text>
            </>
          )}
          {tags && tags.length > 0 && (
            <>
              <span className="text-muted-foreground text-sm">|</span>
              <SingleTagRow tags={tags} />
            </>
          )}
        </div>
      </div>
      <div className="flex gap-x-3 p-3">
        <QuestLink dashboardId={dashboardId} dashboardView={dashboardView} />
        <CommentsPanelToggleButton
          onClick={() => setIsCommentsOpen(true)}
          resourceId={dashboardId}
          resourceType="dashboard"
        />
        <Likes dashboardId={dashboardId} dashboardView={dashboardView} isMobile={false} />
        <Refresh dashboardId={dashboardId} isMobile={false} />
        <TwitterShare dashboardId={dashboardId} isMobile={false} />
      </div>
    </div>
  );
};

interface HeaderCellMobileContentProps {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
  owner?: ProfilePublic;
  setIsCommentsOpen: (open: boolean) => void;
  tags?: UITag[];
}

export function HeaderCellMobileContent({
  cellId,
  dashboardId,
  dashboardView,
  owner,
  setIsCommentsOpen,
  tags,
}: HeaderCellMobileContentProps) {
  return (
    <div className="flex flex-col items-center justify-between h-full py-0.5">
      <HeaderTitle cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} isMobile={true} />
      <div className="flex items-center gap-x-2 justify-start w-full px-2">
        <div className="flex items-center gap-x-2 justify-start">
          <Avatar className="" size={"xs"} src={owner?.avatarUrl ?? ""} />
          <Text size="xs" weight={"semi"}>
            {owner?.name}
          </Text>
        </div>
        {tags && tags.length > 0 && (
          <>
            <span className="text-muted-foreground text-sm">|</span>
            <SingleTagRow tags={tags} />
          </>
        )}
      </div>
      <div className="grid grid-cols-4 gap-2 w-full px-1 py-1">
        <Likes dashboardId={dashboardId} dashboardView={dashboardView} isMobile />
        <Refresh dashboardId={dashboardId} isMobile />
        <CommentsPanelToggleButton
          onClick={() => setIsCommentsOpen(true)}
          resourceId={dashboardId}
          resourceType="dashboard"
          isMobile={true}
        />
        <TwitterShare dashboardId={dashboardId} isMobile />
      </div>
    </div>
  );
}

const HeaderTitle = ({
  cellId,
  dashboardId,
  dashboardView,
  isMobile,
}: {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
  isMobile?: boolean;
}) => {
  const [{ dashboardTitle }] = useDashboardCellContent(cellId, dashboardId, "header", dashboardView);
  const [cellStyle] = useDashboardCellStyles(cellId, dashboardId, "header", dashboardView);

  return (
    <div className={clsx("flex flex-col px-2", { "w-full justify-start": isMobile, " py-1": !isMobile })}>
      <Title title={dashboardTitle} titleSize={isMobile ? "lg" : "2xl"} titleColor={cellStyle?.titleColor} />
    </div>
  );
};

const Likes = ({
  dashboardId,
  dashboardView,
  isMobile,
}: { dashboardId: string; dashboardView: DashboardView; isMobile: boolean }) => {
  const { currentUser } = useCurrentUser();
  const { likes, toggleLikes } = useDashboardSettings(dashboardId);
  const likedByMe = likes?.likedByMe ?? false;
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const onOpenAuthModalChange = (isOpen: boolean) => {
    if (!isOpen) {
      setOpenAuthModal(false);
    }
  };

  const trackedToggleLikes = () => {
    if (!likedByMe) {
      tracking("dashboard_editor_like", "Dashboard Beta");
    }
    toggleLikes?.();
  };

  return (
    <>
      <Button
        onClick={(e) => {
          if (currentUser) {
            trackedToggleLikes();
          } else {
            setOpenAuthModal(true);
          }
        }}
        variant={isMobile ? "ghost" : "secondary"}
        size={isMobile ? "xs" : "md"}
        className={isMobile ? "flex-1" : ""}
      >
        <HeartIcon
          className={clsx("h-4 w-4", {
            "fill-red-50 text-red-50": likedByMe,
            "text-content": !likedByMe,
          })}
        />
        <Text className="ml-1">{likes?.likes ?? 0}</Text>
      </Button>
      <MarketingAuthModal
        open={openAuthModal}
        redirectBackOnClose={false}
        defaultOpen={false}
        onOpenChange={onOpenAuthModalChange}
        persona="viewer"
        origin="dashboard-like"
      />
    </>
  );
};

const Refresh = ({ dashboardId, isMobile }: { dashboardId: string; isMobile: boolean }) => {
  const { refreshDashboard } = useDashboardSettings(dashboardId);
  const { currentUser } = useCurrentUser();
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const onOpenAuthModalChange = (isOpen: boolean) => {
    if (!isOpen) {
      setOpenAuthModal(false);
    }
  };
  return (
    <>
      <Button
        onClick={(e) => {
          if (currentUser) {
            refreshDashboard?.();
          } else {
            setOpenAuthModal(true);
          }
        }}
        variant={isMobile ? "ghost" : "secondary"}
        size={isMobile ? "xs" : "md"}
        className={isMobile ? "flex-1" : ""}
      >
        <RefreshCcwIcon className={clsx("mr-1", isMobile ? "h-4 w-4" : "h-5 w-5", "text-content")} />
        <Text>Refresh</Text>
      </Button>
      <MarketingAuthModal
        open={openAuthModal}
        redirectBackOnClose={false}
        defaultOpen={false}
        onOpenChange={onOpenAuthModalChange}
        persona="viewer"
        origin="dashboard-refresh"
      />
    </>
  );
};

const Contributors = ({ dashboardId }: { dashboardId: string; dashboardView: DashboardView }) => {
  const { contributors } = useDashboardSettings(dashboardId);
  if ((contributors ?? []).length === 0) return null;
  return (
    <div className="flex">
      {contributors?.map((contributor) => (
        <Link to={$path("/:owner/dashboards", { owner: contributor.username })} key={contributor.id}>
          <Tag>@{contributor.username}</Tag>
        </Link>
      ))}
    </div>
  );
};

const TwitterShare = ({ dashboardId, isMobile }: { dashboardId: string; isMobile: boolean }) => {
  const { shareOnTwitter } = useDashboardSettings(dashboardId);
  return (
    <Button
      variant={isMobile ? "ghost" : "secondary"}
      className="flex-1 cursor-pointer"
      asChild
      onClick={shareOnTwitter}
      data-testid="dashboard-twitter-share"
      size={isMobile ? "xs" : "md"}
    >
      <div>
        <TwitterIcon className="h-4 w-4 fill-[#1DA1F2] text-[#1DA1F2]" />
        <Text>Share</Text>
      </div>
    </Button>
  );
};
