import type { DashboardView } from "@fscrypto/domain/dashboard";
import { ParentSize } from "@fscrypto/viz";
import clsx from "clsx";
import { Loader2Icon, Settings2Icon } from "lucide-react";
import { useDashboard } from "../../../hooks/useDashboard";
import { useDashboardCellContent } from "../../../hooks/useDashboardCellContent";
import { useDashboardEditor } from "../../../hooks/useDashboardEditor";
import { useLayout } from "../../../hooks/useLayout";
import { ActiveCellListener } from "../../active-cell/active-cell-listener";
import { DashboardBackgroundContainer } from "../../content-elements/content-elements";
import { GridLayout } from "../../editor/dashboard-grid";
import { Button } from "@fscrypto/ui";

const cellId = "root";

export const RootCellContent = ({
  dashboardId,
  dashboardView,
  isMobile = false,
}: {
  dashboardId: string;
  dashboardView: DashboardView;
  isMobile?: boolean;
}) => {
  const { updateLayouts } = useDashboard(dashboardId);
  const layout = useLayout(cellId, dashboardId, dashboardView, isMobile);
  const { activeLayoutId, setActiveCellId } = useDashboardEditor({ dashboardId });
  if (!layout.length)
    return (
      <div className="size-full bg-muted flex justify-center pt-20 min-h-screen items-center">
        <Loader2Icon className="size-8 text-muted-foreground animate-spin " />
      </div>
    );
  return (
    <RootCellContainer dashboardId={dashboardId} dashboardView={dashboardView}>
      <div
        className={clsx("relative flex w-full flex-1 flex-col", {
          "pt-6": dashboardView === "draft",
        })}
      >
        <GridLayout
          dashboardView={dashboardView}
          dashboardId={dashboardId}
          layoutId={cellId}
          layout={layout}
          draggable={activeLayoutId === cellId && dashboardView === "draft"}
          setLayout={(layout) => {
            updateLayouts?.(layout);
          }}
          isMobile={isMobile}
        />
        {dashboardView === "draft" && (
          <Button className="absolute right-0 top-0 m-1" onClick={() => setActiveCellId(cellId)} iconOnly size="sm">
            <Settings2Icon className="size-5 text-primary-foreground" />
          </Button>
        )}
      </div>
    </RootCellContainer>
  );
};

const RootCellContainer = ({
  dashboardId,
  children,
  dashboardView,
}: {
  dashboardId: string;
  children: React.ReactNode;
  dashboardView: DashboardView;
}) => {
  const [{ theme }] = useDashboardCellContent("root", dashboardId, "root", dashboardView);
  return (
    <div
      className={clsx("flex w-full flex-1 flex-col", {
        light: theme === "light",
        dark: theme === "dark",
        "bg-muted min-h-[6000px] border-2": dashboardView === "draft",
      })}
      data-cell-id={"outside"}
    >
      {dashboardView === "draft" ? (
        <ActiveCellListener cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} hideControls>
          <DashboardBackgroundContainer dashboardId={dashboardId} dashboardView={dashboardView}>
            {children}
          </DashboardBackgroundContainer>
        </ActiveCellListener>
      ) : (
        <DashboardBackgroundContainer dashboardId={dashboardId} dashboardView={dashboardView}>
          {children}
        </DashboardBackgroundContainer>
      )}
    </div>
  );
};

export const DashboardWidthContainer = ({ children }: { children: (width: number) => JSX.Element }) => {
  return (
    <ParentSize className="size-full flex-1 flex flex-col">
      {({ width }) => {
        return <div className="flex flex-col size-full flex-1">{children(width)}</div>;
      }}
    </ParentSize>
  );
};
