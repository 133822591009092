import { DashboardView } from "@fscrypto/domain/dashboard";
import { useDashboard } from "../../../hooks/useDashboard";
import { useDashboardEditor } from "../../../hooks/useDashboardEditor";
import { useLayout } from "../../../hooks/useLayout";
import { ActiveCellListener } from "../../active-cell/active-cell-listener";
import { GridLayout } from "../../editor/dashboard-grid";
import { EmptyPanel } from "../layout-cell/layout-cell-content";

export const TabLayoutCellContent = ({
  cellId,
  dashboardId,
  dashboardView,
  isMobile,
}: {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
  isMobile: boolean;
}) => {
  const { updateLayouts } = useDashboard(dashboardId);
  const layout = useLayout(cellId, dashboardId, dashboardView, isMobile);
  const { activeLayoutId } = useDashboardEditor({ dashboardId });
  return (
    <ActiveCellListener
      cellId={cellId}
      dashboardId={dashboardId}
      dashboardView={dashboardView}
      hideControls
      isMobile={isMobile}
    >
      {layout.length === 0 ? (
        <EmptyPanel variant="tabs" />
      ) : (
        <GridLayout
          dashboardId={dashboardId}
          dashboardView={dashboardView}
          layoutId={cellId}
          layout={layout}
          setLayout={(layout) => {
            updateLayouts?.(layout);
          }}
          draggable={activeLayoutId === cellId && dashboardView === "draft"}
          isMobile={isMobile}
        />
      )}
    </ActiveCellListener>
  );
};
