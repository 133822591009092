import { DashboardView } from "@fscrypto/domain/dashboard";
import { QueryParameter } from "@fscrypto/domain/query";
import { Button, Dialog, DialogContent, DialogTrigger, Text } from "@fscrypto/ui";
import { Badge } from "@fscrypto/ui/badge";
import { Code2Icon, PencilIcon } from "lucide-react";
import { useState } from "react";
import { ParameterInput } from "./param-input";
import { RunParamsButton } from "./params-cell-content";

export const MobileParamsCellContent = ({
  visibleParameters,
  ephemeralValues,
  setEphemeralValues,
  dashboardId,
  paramCellId,
  dashboardView,
}: {
  dashboardId: string;
  paramCellId: string;
  dashboardView: DashboardView;
  visibleParameters: QueryParameter[];
  ephemeralValues?: Record<string, string>;
  setEphemeralValues: (name: string, value: string) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative size-full">
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger className="w-full flex justify-between items-center h-full px-2">
          <div className="flex flex-wrap gap-x-1 gap-y-1">
            {visibleParameters.map((param) => (
              <Badge key={param.id} variant="secondary">
                <Code2Icon className="size-3 mr-1" />
                {param.name}
              </Badge>
            ))}
          </div>
          <Button iconOnly size="sm">
            <PencilIcon className="size-4" />
          </Button>
        </DialogTrigger>
        <DialogContent className="w-screen">
          <div className="flex flex-col w-full gap-y-2">
            <Text size="lg">Parameters</Text>
            {visibleParameters.map((parameter) => (
              <ParameterInput
                key={parameter.id}
                parameter={parameter}
                ephemeralValue={ephemeralValues?.[parameter.name]}
                setEphemeralValues={setEphemeralValues}
                className="w-full"
              />
            ))}
            <RunParamsButton
              dashboardId={dashboardId}
              paramCellId={paramCellId}
              dashboardView={dashboardView}
              onClick={() => setIsOpen(false)}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
