import {
  BackgroundStyles,
  BorderStyles,
  DescriptionColorStyles,
  ShadowStyles,
  StylesAccordion,
  TitleColorStyles,
  TitleDescriptionAlignStyles,
  TitleSizeStyles,
} from "../../style-elements/style-elements";

export const ImageCellStyles = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  return (
    <div className="mb-4 flex h-full w-full flex-col p-3">
      <StylesAccordion>
        <BackgroundStyles cellId={cellId} dashboardId={dashboardId} />
        <TitleSizeStyles cellId={cellId} dashboardId={dashboardId} />
        <TitleDescriptionAlignStyles cellId={cellId} dashboardId={dashboardId} />
        <TitleColorStyles cellId={cellId} dashboardId={dashboardId} />
        <DescriptionColorStyles cellId={cellId} dashboardId={dashboardId} />
        <BorderStyles cellId={cellId} dashboardId={dashboardId} />
        <ShadowStyles cellId={cellId} dashboardId={dashboardId} />
      </StylesAccordion>
    </div>
  );
};
