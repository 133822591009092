import { DashboardView } from "@fscrypto/domain/dashboard";
import { useCallback } from "react";
import { queryFactory } from "~/features/studio-2/query/state/query";
import { tracking } from "~/utils/tracking";
import { useParamCell } from "./useParamCell";

export const useRunEphemeralQueryRunFromParamCell = ({
  dashboardId,
  paramCellId,
  dashboardView,
}: { dashboardId: string; paramCellId: string; dashboardView: DashboardView }) => {
  const {
    childQueryIds = [],
    visibleParametersWithEphemeralValues,
    numberOfRuns = 0,
    setNumberOfRuns,
  } = useParamCell({
    dashboardId,
    paramCellId,
    dashboardView,
  });

  const runParams = useCallback(async () => {
    const queryPromises = childQueryIds.map((queryId) =>
      createQueryPromise(queryId, visibleParametersWithEphemeralValues, paramCellId, dashboardId),
    );
    setNumberOfRuns(numberOfRuns + 1);
    await Promise.all(queryPromises);
    tracking("apply_parameters_to_dashboard", "Dashboard Parameters", {
      dashboardId,
      parametersApplied: JSON.stringify(visibleParametersWithEphemeralValues),
    });
  }, [childQueryIds, visibleParametersWithEphemeralValues, paramCellId, numberOfRuns, setNumberOfRuns]);

  return {
    runParams,
    childQueryIds,
    values: visibleParametersWithEphemeralValues,
    hasChanges: visibleParametersWithEphemeralValues.length > 0,
    numberOfRuns,
  };
};

//this creates a promise to run a query with the given parameters if there are params with values matching the query params
const createQueryPromise = (
  queryId: string,
  visibleParametersWithEphemeralValues: any[],
  paramCellId: string,
  dashboardId: string,
) => {
  const query = queryFactory.fromId(queryId);
  if (!query) return Promise.resolve();

  const queryParams = query.store.get().parameters.map((param) => {
    const visibleParam = visibleParametersWithEphemeralValues.find((vp) => vp.name === param.name);
    return visibleParam ? { ...param, value: visibleParam.value } : param;
  });

  const hasMatchingParams = queryParams.some((param) =>
    visibleParametersWithEphemeralValues.some((vp) => vp.name === param.name),
  );

  return hasMatchingParams ? query.runWithParameters(queryParams, paramCellId, dashboardId) : Promise.resolve();
};
