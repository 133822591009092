import React from "react";
import { CustomIcon, TitleDescription } from "../../config-elements/config-elements";
import { ParametersConfig } from "./paramaters-config";

interface ParamsCellOptionsProps {
  cellId: string;
  dashboardId: string;
}

export const ParamsCellOptions: React.FC<ParamsCellOptionsProps> = ({ cellId, dashboardId }) => {
  return (
    <div className="flex h-full w-full flex-col gap-y-4 p-3 ">
      <TitleDescription cellId={cellId} dashboardId={dashboardId} />
      <CustomIcon cellId={cellId} dashboardId={dashboardId} />
      <ParametersConfig cellId={cellId} dashboardId={dashboardId} />
    </div>
  );
};
