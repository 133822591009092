import { ButtonGroup } from "@fscrypto/ui";
import { useDashboardCellContent } from "../../../hooks/useDashboardCellContent";
import { ConfigAccordion, ConfigAccordionItem, TitleDescription } from "../../config-elements/config-elements";
import { MarkdownField, cleanHtml } from "../../inputs/markdown-field";
import { TextEditorInput } from "../../inputs/text-editor-input";

export const TextCellOptions = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  return (
    <div className="flex h-full w-full flex-col gap-y-4 p-3 ">
      <TitleDescription cellId={cellId} dashboardId={dashboardId} />
      <TextEditorSettings cellId={cellId} dashboardId={dashboardId} />
    </div>
  );
};

const TextEditorSettings = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  const [{ html, editorType = "wysiwyg" }, setContent] = useDashboardCellContent(cellId, dashboardId, "text", "draft");

  return (
    <ConfigAccordion value={["Text Editor"]}>
      <ConfigAccordionItem label="Text Editor">
        <div className="flex flex-col h-[600px]">
          <ButtonGroup.Root>
            <ButtonGroup.Button
              size="sm"
              variant={editorType === "wysiwyg" ? "outline" : "secondary"}
              onClick={() => setContent({ editorType: "wysiwyg" })}
            >
              WYSIWYG
            </ButtonGroup.Button>
            <ButtonGroup.Button
              size="sm"
              variant={editorType === "markdown" ? "outline" : "secondary"}
              onClick={() => setContent({ editorType: "markdown" })}
            >
              Markdown
            </ButtonGroup.Button>
          </ButtonGroup.Root>
          <div className="relative flex-grow mt-3">
            <div className="absolute inset-0 overflow-hidden p-1">
              {editorType === "wysiwyg" && (
                <TextEditorInput html={html ?? ""} onChange={(html) => setContent({ html })} />
              )}
              {editorType === "markdown" && (
                <MarkdownField value={html ?? ""} onChange={(html) => setContent({ html: cleanHtml(html) })} />
              )}
            </div>
          </div>
        </div>
      </ConfigAccordionItem>
    </ConfigAccordion>
  );
};
