import { Button, Card, ScrollArea, Text, Tooltip } from "@fscrypto/ui";
import { Tabs, TabsList, TabsTrigger } from "@fscrypto/ui/tabs";
import { TabsContent } from "@radix-ui/react-tabs";
import {
  Code2Icon,
  Heading2Icon,
  HomeIcon,
  ImageIcon,
  LayoutDashboardIcon,
  LayoutIcon,
  LayoutTemplateIcon,
  LineChartIcon,
  LucideIcon,
  MousePointerClick,
  PaletteIcon,
  PanelTopIcon,
  SettingsIcon,
  TableIcon,
  TypeIcon,
  XIcon,
} from "lucide-react";

import { CellVariant } from "@fscrypto/domain/dashboard";
import clsx from "clsx";
import { ReactNode } from "react";
import { tracking } from "~/utils/tracking";
import { useDashboardCell } from "../../hooks/useDashboardCell";
import { useDashboardEditor } from "../../hooks/useDashboardEditor";
import { CellConfigRenderer, CellStylesRenderer } from "../cells/cell-renderer";

const Root = ({ children, dashboardId }: { children: ReactNode; dashboardId: string }) => {
  const { showSettingsPanel, openSettingsPanel } = useDashboardEditor({ dashboardId });
  return (
    <div className="flex h-full max-h-full w-[360px] flex-col p-2 bg-muted">
      <div className="flex-1 overflow-hidden bg-background rounded-xl border flex flex-col p-2 shadow-xl">
        {children}
      </div>
    </div>
  );
};

const ConfigTabs = ({
  settings,
  styles,
  dashboardId,
}: {
  settings: ReactNode;
  styles: ReactNode;
  dashboardId: string;
}) => {
  const { activeConfigPanel, setActiveConfigPanel, closeSettingsPanel } = useDashboardEditor({ dashboardId });
  return (
    <Tabs className="flex h-full flex-col w-full" value={activeConfigPanel ?? "settings"}>
      <TabsList className="w-full rounded-none bg-background" size="md">
        <TabsTrigger
          value="settings"
          size="md"
          onClick={() => {
            setActiveConfigPanel("settings");
            tracking("dashboard_editor_settings", "Dashboard Beta");
          }}
          className={clsx("w-full shadow-none rounded-b-none data-[state=active]:shadow-none border-b-2", {
            "border-primary/80": activeConfigPanel === "settings",
            "border-transparent": activeConfigPanel !== "settings",
          })}
        >
          <SettingsIcon className="size-4 text-muted-foreground mr-2" />
          <Text>Settings</Text>
        </TabsTrigger>
        <TabsTrigger
          value="styles"
          size="md"
          onClick={() => {
            setActiveConfigPanel("styles");
            tracking("dashboard_editor_styles", "Dashboard Beta");
          }}
          className={clsx("w-full shdaow-none rounded-b-none data-[state=active]:shadow-none border-b-2", {
            "border-primary/80": activeConfigPanel === "styles",
            "border-transparent": activeConfigPanel !== "styles",
          })}
        >
          <PaletteIcon className="size-4 text-muted-foreground mr-2" />
          <Text>Styles</Text>
        </TabsTrigger>
      </TabsList>
      <TabsContent value="settings" className="h-full max-h-full flex-1 overflow-hidden px-2 pb-20">
        <div className="size-full ">{settings}</div>
      </TabsContent>
      <TabsContent value="styles" className="h-full max-h-full flex-1 overflow-hidden px-2 pb-20">
        {styles}
      </TabsContent>
    </Tabs>
  );
};

const SettingsPanel = ({ dashboardId }: { dashboardId: string }) => {
  const { activeCellId } = useDashboardEditor({ dashboardId });
  return (
    <div className="flex h-full max-h-full w-full flex-1 flex-col">
      {activeCellId ? (
        <ScrollArea className="h-full w-full max-w-full">
          <CellConfigRenderer cellId={activeCellId ?? undefined} dashboardId={dashboardId} key={activeCellId} />
        </ScrollArea>
      ) : (
        <EmptySettingsPanel />
      )}
    </div>
  );
};

export const EmptySettingsPanel = () => {
  return (
    <div className="flex h-full w-full flex-1 flex-col items-center justify-center">
      <Text size="sm" className="text-muted-foreground">
        Select an Element to Configure its options{" "}
      </Text>
      <MousePointerClick className="text-muted-foreground size-12 mt-4" />
    </div>
  );
};

export const EmptyStylesPanel = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <Text size="sm" className="text-muted-foreground">
        Select an Element to Configure its options{" "}
      </Text>
      <MousePointerClick className="text-muted-foreground size-12 mt-4" />
    </div>
  );
};

const StylesPanel = ({ dashboardId }: { dashboardId: string }) => {
  const { activeCellId } = useDashboardEditor({ dashboardId });
  return (
    <div className="flex h-full max-h-full w-full flex-1 flex-col pb-3">
      {activeCellId ? (
        <ScrollArea className="h-full w-full">
          <CellStylesRenderer cellId={activeCellId ?? undefined} dashboardId={dashboardId} key={activeCellId} />
        </ScrollArea>
      ) : (
        <EmptyStylesPanel />
      )}
    </div>
  );
};

const Title = ({ dashboardId, onClose }: { dashboardId: string; onClose: () => void }) => {
  const { activeCellId } = useDashboardEditor({ dashboardId });
  const { cell } = useDashboardCell(activeCellId ?? "", dashboardId, "draft");
  const variant = cell?.variant ?? "root";
  const { title, description, icon: Icon } = titleMap[variant as CellVariant];
  return (
    <div className="mb-3">
      <div className="flex items-center justify-between py-2">
        <div className="flex items-center gap-x-3 pl-2">
          <Icon className="size-6 text-primary/80" />
          <Text size="lg" weight={"bold"} className="text-foreground/80">
            {title}
          </Text>
        </div>
        <Button onClick={onClose} variant="ghost" iconOnly size="sm">
          <XIcon className="size-5" />
        </Button>
      </div>
      <Text size="sm" className="text-muted-foreground px-2">
        {description}
      </Text>
    </div>
  );
};
export const DashboardConfig = {
  Root,
  Tabs: ConfigTabs,
  SettingsPanel,
  StylesPanel,
  Title,
};

const titleMap: Record<CellVariant, { title: string; icon: LucideIcon; description: string }> = {
  root: {
    title: "Dashboard Root Config",
    icon: HomeIcon,
    description: "The main container for all dashboard elements",
  },
  image: {
    title: "Image",
    icon: ImageIcon,
    description: "Add and configure images in your dashboard",
  },
  layout: {
    title: "Layout",
    icon: LayoutIcon,
    description: "Organize your dashboard content with custom layouts",
  },
  text: {
    title: "Text",
    icon: TypeIcon,
    description: "Add and format text content in your dashboard",
  },
  base: {
    title: "Base Element",
    icon: LayoutDashboardIcon,
    description: "Basic building block for dashboard elements",
  },
  header: {
    title: "Header",
    icon: PanelTopIcon,
    description: "Configure the dashboard header",
  },
  "tabs-panel": {
    title: "Tabs Panel",
    icon: PanelTopIcon,
    description: "Create and manage tabbed content",
  },
  "tab-layout": {
    title: "Tab Layout",
    icon: LayoutTemplateIcon,
    description: "Organize content within individual tabs",
  },
  visualization: {
    title: "Visualization",
    icon: LineChartIcon,
    description: "Add and configure data visualizations",
  },
  "legacy-table": {
    title: "Legacy Table",
    icon: TableIcon,
    description: "Add and configure legacy table components",
  },
  heading: {
    title: "Heading",
    icon: Heading2Icon,
    description: "Add and style headings in your dashboard",
  },
  params: {
    title: "Parameters",
    icon: Code2Icon,
    description: "Configure dashboard parameters and filters",
  },
};
