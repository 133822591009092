import { DashboardView } from "@fscrypto/domain/dashboard";
import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Tooltip } from "@fscrypto/ui";
import React from "react";
import { useState } from "react";
import MarketingAuthModal from "~/features/app-shell/marketing/marketing-auth-modal";
import { useCurrentUser } from "~/features/current-user";
import { useParamCell } from "../../../hooks/param-cells/useParamCell";
import { useRunEphemeralQueryRunFromParamCell } from "../../../hooks/param-cells/useRunEphemeralQueryRunFromParamCell";
import { useDashboardCell } from "../../../hooks/useDashboardCell";
import { ActiveCellListener } from "../../active-cell/active-cell-listener";
import { BackgroundContainer, BorderContainer, TitleDescription } from "../../content-elements/content-elements";
import { MobileParamsCellContent } from "./mobile-params-cell-content";
import { ParameterInput } from "./param-input";

interface ParamsCellContentProps {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
  isMobile: boolean;
}

export const ParamsCellContent: React.FC<ParamsCellContentProps> = ({
  cellId,
  dashboardId,
  dashboardView,
  isMobile,
}) => {
  const { cell } = useDashboardCell(cellId, dashboardId, dashboardView);
  return (
    <ActiveCellListener
      cellId={cellId}
      dashboardId={dashboardId}
      dashboardView={dashboardView}
      preventMove
      isMobile={isMobile}
    >
      <BorderContainer cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView}>
        <BackgroundContainer cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView}>
          <div>
            <TitleDescription cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} />
          </div>
          <ParametersContent
            cellId={cellId}
            dashboardId={dashboardId}
            dashboardView={dashboardView}
            key={cell.parentCellId}
            isMobile={isMobile}
          />
        </BackgroundContainer>
      </BorderContainer>
    </ActiveCellListener>
  );
};

interface ParametersProps {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
  isMobile: boolean;
}

export const ParametersContent = ({ cellId, dashboardId, dashboardView, isMobile }: ParametersProps) => {
  const {
    visibleParameters = [],
    ephemeralValues,
    setEphemeralValues,
  } = useParamCell({
    dashboardId,
    paramCellId: cellId,
    dashboardView: "draft",
  });

  if (isMobile && visibleParameters.length > 1) {
    return (
      <MobileParamsCellContent
        visibleParameters={visibleParameters}
        ephemeralValues={ephemeralValues}
        setEphemeralValues={setEphemeralValues}
        dashboardId={dashboardId}
        paramCellId={cellId}
        dashboardView={dashboardView}
      />
    );
  }

  return (
    <div className="flex items-center justify-between h-full px-2 py-2 gap-x-2">
      <div className="flex items-center gap-x-2 flex-wrap gap-y-1">
        {visibleParameters.map((parameter) => (
          <ParameterInput
            key={parameter.id}
            parameter={parameter}
            ephemeralValue={ephemeralValues?.[parameter.name]}
            setEphemeralValues={setEphemeralValues}
          />
        ))}
        <RunParamsButton dashboardId={dashboardId} paramCellId={cellId} dashboardView={dashboardView} />
      </div>
    </div>
  );
};

interface SelectInputProps {
  name: string;
  value: string;
  onChange: (val: string) => void;
  restrictedValues: string;
  disabled: boolean;
}

export const SelectInput = ({ name, value, onChange, restrictedValues, disabled }: SelectInputProps) => {
  const values = restrictedValues.split(",");
  return (
    <Select onValueChange={onChange} value={value}>
      <SelectTrigger className="w-full">
        <div className="">{name}</div>
        <div className="text-border px-3">|</div>
        <SelectValue placeholder="Select tab" />
      </SelectTrigger>
      <SelectContent>
        {values.map((value) => (
          <SelectItem value={value} key={value}>
            {value}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export const RunParamsButton = ({
  dashboardId,
  paramCellId,
  dashboardView,
  onClick,
}: {
  dashboardId: string;
  paramCellId: string;
  dashboardView: DashboardView;
  onClick?: () => void;
}) => {
  const { runParams, hasChanges } = useRunEphemeralQueryRunFromParamCell({
    dashboardId,
    paramCellId,
    dashboardView,
  });

  const { currentUser } = useCurrentUser();
  const [openAuthModal, setOpenAuthModal] = useState(false);

  const handleAuthModalOpenChange = (isOpen: boolean) => {
    setOpenAuthModal(isOpen);
  };

  return (
    <>
      <Tooltip content="Change Values to Run Parameters" side="right" display={!hasChanges}>
        <Button
          disabled={!hasChanges}
          onClick={() => {
            if (currentUser) {
              onClick?.();
              runParams();
            } else {
              setOpenAuthModal(true);
            }
          }}
          onDragStart={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
          onMouseMove={(e) => e.stopPropagation()}
        >
          Run Params
        </Button>
      </Tooltip>
      <MarketingAuthModal
        open={openAuthModal}
        redirectBackOnClose={false}
        defaultOpen={false}
        onOpenChange={handleAuthModalOpenChange}
        persona="viewer"
        origin="dashboard-refresh"
      />
    </>
  );
};
