import { Button, Text } from "@fscrypto/ui";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@fscrypto/ui/accordion";
import clsx from "clsx";
import { DragControls, Reorder, useDragControls } from "framer-motion";
import { GripVerticalIcon, Trash2Icon } from "lucide-react";
import { nanoid } from "nanoid";
import { useDashboard } from "../../../hooks/useDashboard";
import { useDashboardCell } from "../../../hooks/useDashboardCell";
import { useDashboardCellContent } from "../../../hooks/useDashboardCellContent";
import { useDashboardEditor } from "../../../hooks/useDashboardEditor";
import { useTabState } from "../../../hooks/useTabState";
import { ConfigTitleAndClose, CustomIcon, TitleDescription } from "../../config-elements/config-elements";
import { TextInputField } from "../../inputs/text-field";

export const TabsPanelCellOptions = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  return (
    <div className="flex h-full flex-col gap-y-4 p-3 w-[310px]">
      <ConfigTitleAndClose title="Tabs Panel" dashboardId={dashboardId} />
      <Text size="sm" color={"muted"}>
        This panel allows you to nest other panels.
      </Text>
      <TitleDescription cellId={cellId} dashboardId={dashboardId} />
      <Text size="xl" weight="semi">
        Tabs Manager
      </Text>
      <AddTab cellId={cellId} dashboardId={dashboardId} />
      <TabsManager cellId={cellId} dashboardId={dashboardId} />
    </div>
  );
};

const AddTab = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  const { addDashboardCell } = useDashboard(dashboardId);
  const { cell } = useDashboardCell(cellId, dashboardId, "draft");
  const { setActiveLayoutId } = useDashboardEditor({ dashboardId });
  const { setActiveTabId } = useTabState(cellId, dashboardId, "draft");
  return (
    <Button
      onClick={() => {
        const newId = `tab-layout-${nanoid(4)}`;
        addDashboardCell?.({
          id: newId,
          variant: "tab-layout",
          parentId: cellId,
          content: {
            title: `New Tab ${cell?.childCellIds?.length ?? 0 + 1}`,
          },
          style: {
            border: {
              width: 0,
            },
          },
        });
        setActiveLayoutId(newId, true);
        setActiveTabId(newId);
      }}
    >
      Add Tab
    </Button>
  );
};

const TabsManager = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  const { cell, setCell } = useDashboardCell(cellId, dashboardId, "draft");
  const { setActiveTabId } = useTabState(cellId, dashboardId, "draft");
  const childCellIds = cell?.childCellIds ?? [];
  return (
    <div className="w-full">
      <List
        cellIds={childCellIds}
        reorderCellIds={(cellIds) => setCell?.({ childCellIds: cellIds })}
        dashboardId={dashboardId}
        setActiveTabId={setActiveTabId}
        tabsCellId={cellId}
      />
    </div>
  );
};

const TabSettings = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  const [{ title }, setContent] = useDashboardCellContent(cellId, dashboardId, "tab-layout", "draft");
  return (
    <div className="flex w-full flex-col gap-y-2">
      <TextInputField value={title ?? ""} onChange={(title) => setContent({ title })} label="tab title" />
      <CustomIcon cellId={cellId} dashboardId={dashboardId} />
    </div>
  );
};

function List({
  cellIds,
  reorderCellIds,
  dashboardId,
  setActiveTabId,
  tabsCellId,
}: {
  cellIds: string[];
  dashboardId: string;
  reorderCellIds: (ids: string[]) => void;
  setActiveTabId: (id: string | null) => void;
  tabsCellId: string;
}) {
  return (
    <Reorder.Group axis="y" values={cellIds} onReorder={reorderCellIds} className="flex max-w-full flex-col gap-y-2">
      {cellIds.map((id) => (
        <DragItem
          cellId={id}
          key={id}
          dashboardId={dashboardId}
          cellIds={cellIds}
          setActiveTabId={setActiveTabId}
          tabsCellId={tabsCellId}
        />
      ))}
    </Reorder.Group>
  );
}

const DragItem = ({
  cellId,
  dashboardId,
  cellIds,
  setActiveTabId,
  tabsCellId,
}: {
  cellId: string;
  dashboardId: string;
  cellIds: string[];
  setActiveTabId: (id: string | null) => void;
  tabsCellId: string;
}) => {
  const dragControls = useDragControls();
  return (
    <Reorder.Item
      key={cellId}
      value={cellId}
      className="flex w-full items-center"
      dragListener={false}
      dragControls={dragControls}
    >
      <TabAccordion
        dragControls={dragControls}
        cellId={cellId}
        key={cellId}
        dashboardId={dashboardId}
        cellIds={cellIds}
        setActiveTabId={setActiveTabId}
        tabsCellId={tabsCellId}
      />
    </Reorder.Item>
  );
};

const TabAccordion = ({
  dragControls,
  cellId,
  dashboardId,
  cellIds,
  setActiveTabId,
  tabsCellId,
}: {
  dragControls: DragControls;
  cellId: string;
  dashboardId: string;
  cellIds: string[];
  setActiveTabId: (id: string | null) => void;
  tabsCellId: string;
}) => {
  const [{ title }] = useDashboardCellContent(cellId, dashboardId, "tab-layout", "draft");
  const { removeDashboardCell } = useDashboard(dashboardId);
  const { setActiveCellId, setActiveLayoutId, activeLayoutId } = useDashboardEditor({ dashboardId });
  return (
    <Accordion type="multiple" className="flex w-full">
      <AccordionItem
        value="foo"
        className={clsx("w-full rounded-lg border px-2", {
          "border-primary border-dashed": activeLayoutId === cellId,
        })}
        onClick={(e) => {
          // add a small timeout so that the active layout transition isn;t conflicting with the accordion open/close transition
          setTimeout(() => {
            setActiveLayoutId?.(cellId, true);
            setActiveTabId?.(cellId);
            setActiveCellId?.(tabsCellId);
          }, 50);
        }}
      >
        <AccordionTrigger caretPosition="right" className="h-full w-full">
          <div className="flex w-full items-center justify-between  overflow-hidden">
            <div
              className="draggable-handle bg-muted flex h-8 items-center justify-center rounded border shadow-sm"
              style={{ cursor: "grab" }}
              onPointerDown={(event) => {
                event.stopPropagation();
                dragControls.start(event);
              }}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <GripVerticalIcon className="size-6 text-muted-foreground" />
            </div>
            <div className="flex w-full items-center justify-between overflow-hidden">
              <Text className="w-full overflow-hidden truncate px-2 text-left" weight={"medium"} size="sm">
                {title}
              </Text>
              {cellIds.length > 1 && (
                <Button
                  iconOnly
                  size="sm"
                  variant="ghost"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeDashboardCell?.(cellId);
                    const nextCellId = cellIds.filter((id) => id !== cellId)[0] ?? "root";
                    if (nextCellId !== "root") {
                      setActiveTabId?.(nextCellId);
                      setActiveLayoutId?.(nextCellId, true);
                    } else {
                      setActiveLayoutId?.(tabsCellId, true);
                      setActiveTabId?.(null);
                    }
                  }}
                >
                  <Trash2Icon className="size-4" />
                </Button>
              )}
            </div>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <TabSettings cellId={cellId} dashboardId={dashboardId} />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
