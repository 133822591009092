import { useDashboardCellContent } from "../../../hooks/useDashboardCellContent";
import { DarkModeField } from "../../inputs/dark-mode-field";

export const RootCellConfig = ({ dashboardId }: { dashboardId: string }) => {
  const [cellContent, setCellContent] = useDashboardCellContent("root", dashboardId, "root", "draft");
  return (
    <div className="flex h-full w-full flex-col gap-y-4 p-3 ">
      <DarkModeField
        theme={cellContent?.theme}
        setTheme={(theme) => {
          setCellContent({ ...cellContent, theme });
        }}
      />
    </div>
  );
};
