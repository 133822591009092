import type { DashboardView } from "@fscrypto/domain/dashboard";
import { memo } from "react";
import { useDashboardCell } from "../../hooks/useDashboardCell";
import { DashboardConfig, EmptySettingsPanel, EmptyStylesPanel } from "../editor/dashboard-config";
import { HeaderCellConfig } from "./header-cell/header-cell-config";
import { HeaderCellContent } from "./header-cell/header-cell-content";
import { HeaderCellStyles } from "./header-cell/header-cell-styles";
import { HeadingCellContent } from "./heading-cell/heading-cell-content";
import { HeadingCellOptions } from "./heading-cell/heading-cell-options";
import { HeadingCellStyles } from "./heading-cell/heading-cell-styles";
import { ImageCellContent } from "./image-cell/image-cell-content";
import { ImageCellOptions } from "./image-cell/image-cell-options";
import { ImageCellStyles } from "./image-cell/image-cell-styles";
import { LayoutCellContent } from "./layout-cell/layout-cell-content";
import { LayoutCellOptions } from "./layout-cell/layout-cell-options";
import { LayoutCellStyles } from "./layout-cell/layout-cell-styles";
import { ParamsCellContent } from "./params-cell/params-cell-content";
import { ParamsCellOptions } from "./params-cell/params-cell-options";
import { ParamsCellStyles } from "./params-cell/params-cell-styles";
import { RootCellConfig } from "./root-cell/root-cell-config";
import { RootCellStyles } from "./root-cell/root-cell-style";
import { TabLayoutCellContent } from "./tab-layout-cell/tab-layout-cell-content";
import { TabLayoutCellOptions } from "./tab-layout-cell/tab-layout-options";
import { TabsPanelCellContent } from "./tabs-panel-cell/tabs-panel-cell-content";
import { TabsPanelCellOptions } from "./tabs-panel-cell/tabs-panel-cell-options";
import { TabsPanelCellStyles } from "./tabs-panel-cell/tabs-panel-cell-styles";
import { TextCellContent } from "./text-cell/text-cell-content";
import { TextCellOptions } from "./text-cell/text-cell-options";
import { TextCellStyles } from "./text-cell/text-cell-styles";
import { VisualizationCellContent } from "./visualization-cell/visualization-cell-content";
import { VisualizationCellOptions } from "./visualization-cell/visualization-cell-options";
import { VisualizationCellStyles } from "./visualization-cell/visualization-cell-styles";

const CellContentRendererComponent = ({
  cellId,
  dashboardId,
  dashboardView,
  layoutId,
  isMobile,
}: {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
  layoutId: string;
  isMobile: boolean;
}) => {
  const { cell } = useDashboardCell(cellId, dashboardId, dashboardView);
  switch (cell?.variant) {
    case "header":
      return (
        <HeaderCellContent
          cellId={cellId}
          dashboardId={dashboardId}
          dashboardView={dashboardView}
          isMobile={isMobile}
        />
      );
    case "layout":
      return (
        <LayoutCellContent
          cellId={cellId}
          dashboardId={dashboardId}
          dashboardView={dashboardView}
          isMobile={isMobile}
        />
      );
    case "text":
      return (
        <TextCellContent cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} isMobile={isMobile} />
      );
    case "image":
      return (
        <ImageCellContent cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} isMobile={isMobile} />
      );
    case "tabs-panel":
      return (
        <TabsPanelCellContent
          cellId={cellId}
          dashboardId={dashboardId}
          dashboardView={dashboardView}
          isMobile={isMobile}
        />
      );
    case "visualization":
      return (
        <VisualizationCellContent
          cellId={cellId}
          dashboardId={dashboardId}
          dashboardView={dashboardView}
          layoutId={layoutId}
          isMobile={isMobile}
        />
      );
    case "tab-layout":
      return (
        <TabLayoutCellContent
          cellId={cellId}
          dashboardId={dashboardId}
          dashboardView={dashboardView}
          isMobile={isMobile}
        />
      );
    case "heading":
      return (
        <HeadingCellContent
          cellId={cellId}
          dashboardId={dashboardId}
          dashboardView={dashboardView}
          isMobile={isMobile}
        />
      );
    case "params":
      return (
        <ParamsCellContent
          cellId={cellId}
          dashboardId={dashboardId}
          dashboardView={dashboardView}
          isMobile={isMobile}
        />
      );
    default:
      return null;
  }
};
export const CellContentRenderer = memo(CellContentRendererComponent);
const CellConfigRendererComponent = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  const { cell } = useDashboardCell(cellId, dashboardId, "draft");
  switch (cell?.variant) {
    case "root":
      return <RootCellConfig dashboardId={dashboardId} />;
    case "header":
      return <HeaderCellConfig cellId={cellId} dashboardId={dashboardId} />;
    case "text":
      return <TextCellOptions cellId={cellId} dashboardId={dashboardId} />;
    case "image":
      return <ImageCellOptions cellId={cellId} dashboardId={dashboardId} />;
    case "layout":
      return <LayoutCellOptions cellId={cellId} dashboardId={dashboardId} />;
    case "tabs-panel":
      return <TabsPanelCellOptions cellId={cellId} dashboardId={dashboardId} />;
    case "tab-layout":
      return <TabLayoutCellOptions cellId={cellId} dashboardId={dashboardId} />;
    case "visualization":
      return <VisualizationCellOptions cellId={cellId} dashboardId={dashboardId} />;
    case "heading":
      return <HeadingCellOptions cellId={cellId} dashboardId={dashboardId} />;
    case "params":
      return <ParamsCellOptions cellId={cellId} dashboardId={dashboardId} />;
    default:
      return <EmptySettingsPanel />;
  }
};

export const CellConfigRenderer = memo(CellConfigRendererComponent);

export const CellStylesRendererComponent = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  const { cell } = useDashboardCell(cellId, dashboardId, "draft");
  switch (cell?.variant) {
    case "root":
      return <RootCellStyles cellId={cellId} dashboardId={dashboardId} />;
    case "header":
      return <HeaderCellStyles cellId={cellId} dashboardId={dashboardId} />;
    case "text":
      return <TextCellStyles cellId={cellId} dashboardId={dashboardId} />;
    case "layout":
      return <LayoutCellStyles cellId={cellId} dashboardId={dashboardId} />;
    case "tabs-panel":
      return <TabsPanelCellStyles cellId={cellId} dashboardId={dashboardId} />;
    case "visualization":
      return <VisualizationCellStyles cellId={cellId} dashboardId={dashboardId} />;
    case "heading":
      return <HeadingCellStyles cellId={cellId} dashboardId={dashboardId} />;
    case "image":
      return <ImageCellStyles cellId={cellId} dashboardId={dashboardId} />;
    case "params":
      return <ParamsCellStyles cellId={cellId} dashboardId={dashboardId} />;
    default:
      return <EmptyStylesPanel />;
  }
};

export const CellStylesRenderer = memo(CellStylesRendererComponent);
