import { DashboardView } from "@fscrypto/domain/dashboard";
import { useDashboard } from "../../../hooks/useDashboard";
import { useDashboardEditor } from "../../../hooks/useDashboardEditor";
import { useLayout } from "../../../hooks/useLayout";
import { ActiveCellListener } from "../../active-cell/active-cell-listener";
import { BackgroundContainer, BorderContainer, TitleDescription } from "../../content-elements/content-elements";
import { GridLayout } from "../../editor/dashboard-grid";
import { BlocksIcon, PackageOpenIcon } from "lucide-react";
import { Button, Text } from "@fscrypto/ui";
import { useUserStateMachine } from "~/state/machines/user-state/user-state";

export const LayoutCellContent = ({
  cellId,
  dashboardId,
  dashboardView,
  isMobile,
}: {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
  isMobile: boolean;
}) => {
  const { updateLayouts } = useDashboard(dashboardId);
  const layout = useLayout(cellId, dashboardId, dashboardView, isMobile);
  const { activeLayoutId } = useDashboardEditor({ dashboardId });

  return (
    <ActiveCellListener cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} isMobile={isMobile}>
      <BorderContainer cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView}>
        <BackgroundContainer cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView}>
          <div className={"w-full"}>
            <TitleDescription cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} />
          </div>
          <div className="flex flex-1 flex-col pt-1">
            {layout.length === 0 ? (
              <EmptyPanel variant="layout" />
            ) : (
              <GridLayout
                dashboardView={dashboardView}
                dashboardId={dashboardId}
                layoutId={cellId}
                layout={layout}
                setLayout={(layout) => {
                  updateLayouts?.(layout);
                }}
                draggable={activeLayoutId === cellId && dashboardView === "draft"}
                isMobile={isMobile}
              />
            )}
          </div>
        </BackgroundContainer>
      </BorderContainer>
    </ActiveCellListener>
  );
};

export const EmptyPanel = ({ variant }: { variant: "tabs" | "layout" }) => {
  const { isSidebarOpen, setIsSidebarOpen, sidebarTab = "my-work", setSidebarTab } = useUserStateMachine();
  const isWidgetsActive = isSidebarOpen && sidebarTab === "widgets";
  return (
    <div className="size-full flex justify-center p-8 rounded-lg flex-col items-center bg-gradient-to-b from-transparent via-transparent to-primary/10">
      <PackageOpenIcon className="w-12 h-12 text-muted-foreground mb-4" />
      <Text className="text-lg font-semibold text-content/80 mb-2">Empty {variant === "tabs" ? "Tab" : "Layout"}</Text>
      <Text className="text-sm text-muted-foreground text-center mb-4 w-80">
        This {variant === "tabs" ? "tab" : "layout"} is empty. Start building by adding content to bring your ideas to
        life.
      </Text>
      {!isWidgetsActive && (
        <Button
          onClick={() => {
            setIsSidebarOpen(true);
            setSidebarTab("widgets");
          }}
        >
          <BlocksIcon className="mr-2 size-4" />
          Open Widgets panel
        </Button>
      )}
    </div>
  );
};
