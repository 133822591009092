import {
  BackgroundStyles,
  BorderStyles,
  DashboardBackgroundStyles,
  ShadowStyles,
  StylesAccordion,
} from "../../style-elements/style-elements";

export const RootCellStyles = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  return (
    <div className="flex h-full w-full flex-col p-3">
      <StylesAccordion>
        <DashboardBackgroundStyles dashboardId={dashboardId} />
        <BackgroundStyles cellId={cellId} dashboardId={dashboardId} label="Default Cell Background" />
        <BorderStyles cellId={cellId} dashboardId={dashboardId} label="Default Cell Border" />
        <ShadowStyles cellId={cellId} dashboardId={dashboardId} label="Default Cell Shadow" />
      </StylesAccordion>
    </div>
  );
};
