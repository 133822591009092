import { CellStyle } from "@fscrypto/domain/dashboard";
import { ButtonGroup, Card, Text } from "@fscrypto/ui";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@fscrypto/ui/accordion";
import clsx from "clsx";
import {
  AlignCenterHorizontal,
  AlignCenterVertical,
  AlignEndHorizontal,
  AlignEndVertical,
  AlignStartHorizontal,
  AlignStartVertical,
} from "lucide-react";
import { ReactNode } from "react";
import { useDashboardCellStyles } from "../../hooks/useDashboardCellStyles";
import { BackgroundField } from "../inputs/background-field";
import { BorderField } from "../inputs/border-field";
import { ShadowField } from "../inputs/shadow-field";
import { TextColorField } from "../inputs/text-color-field";

export const CellStylesPanel = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  if (!cellId) return null;
  return (
    <div className="flex h-full w-full flex-col gap-y-4 p-3 ">
      <Accordion type="multiple">
        <AccordionItem value={"basics"} key={"basics"} className="border-b">
          <AccordionTrigger caretPosition="left" className="py-4">
            <Text weight="semi" className="px-2">
              Basics
            </Text>
          </AccordionTrigger>
          <AccordionContent className="p-2">fhdsjhfdksjfhjk</AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export const EmptyStylesPanel = () => {
  return <Card>Styles</Card>;
};

export const StylesAccordion = ({ children }: { children: ReactNode }) => {
  return <Accordion type="multiple">{children}</Accordion>;
};

export const StylesAccordionItem = ({ children, label }: { children: ReactNode; label: string }) => {
  return (
    <AccordionItem value={label} key={label} className="">
      <AccordionTrigger caretPosition="left" className="py-4">
        <Text weight="semi" className="px-2">
          {label}
        </Text>
      </AccordionTrigger>
      <AccordionContent className="flex flex-col gap-y-4">{children}</AccordionContent>
    </AccordionItem>
  );
};

export const BackgroundStyles = ({
  cellId,
  dashboardId,
  label = "Background",
}: {
  cellId: string;
  dashboardId: string;
  label?: string;
}) => {
  const [{ background }, setStyle] = useDashboardCellStyles(cellId, dashboardId, "base", "draft");
  return (
    <StylesAccordionItem label={label}>
      <BackgroundField
        background={background}
        onChange={(bg) => {
          setStyle({ background: bg });
        }}
      />
    </StylesAccordionItem>
  );
};

export const TitleSizeStyles = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  const [{ titleSize }, setContent] = useDashboardCellStyles(cellId, dashboardId, "base", "draft");
  return <TitleSize value={titleSize ?? "lg"} onChange={(value) => setContent({ titleSize: value })} />;
};

export const ShadowStyles = ({
  cellId,
  dashboardId,
  label = "Shadow",
}: {
  cellId: string;
  dashboardId: string;
  label?: string;
}) => {
  const [{ shadow }, setStyle] = useDashboardCellStyles(cellId, dashboardId, "base", "draft");
  return (
    <StylesAccordionItem label={label}>
      <ShadowField
        shadow={shadow}
        onChange={(shadow) => {
          setStyle({ shadow });
        }}
      />
    </StylesAccordionItem>
  );
};

export const DashboardBackgroundStyles = ({ dashboardId }: { dashboardId: string }) => {
  const [{ dashboardBackground }, setStyle] = useDashboardCellStyles("root", dashboardId, "root", "draft");
  return (
    <StylesAccordionItem label={"Dashboard Background"}>
      <BackgroundField
        background={dashboardBackground}
        onChange={(bg) => {
          setStyle({ dashboardBackground: bg });
        }}
      />
    </StylesAccordionItem>
  );
};

export const TitleStyles = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  const [cellStyles, setContent] = useDashboardCellStyles(cellId, dashboardId, "base", "draft");
  return (
    <AccordionItem value={"title"} key={"title"} className="">
      <AccordionTrigger caretPosition="left" className="py-4">
        <Text weight="semi" className="px-2">
          Title
        </Text>
      </AccordionTrigger>
      <AccordionContent className="flex flex-col gap-y-4 p-2">
        <TitleSize value={cellStyles?.titleSize ?? "lg"} onChange={(value) => setContent({ titleSize: value })} />
      </AccordionContent>
    </AccordionItem>
  );
};

export const VerticalAlignButtonGroup = ({
  value,
  onChange,
  label = "Vertical Align",
}: {
  value: "start" | "center" | "end";
  onChange: (value: "start" | "center" | "end") => void;
  label?: string;
}) => {
  return (
    <StylesAccordion>
      <StylesAccordionItem label={label}>
        <ButtonGroup>
          <ButtonGroup.Button onClick={() => onChange("start")}>
            <AlignStartVertical
              className={clsx("size-5 text-muted-foreground", {
                "text-primary": value === "start",
              })}
            />
          </ButtonGroup.Button>
          <ButtonGroup.Button onClick={() => onChange("center")}>
            <AlignCenterVertical
              className={clsx("size-5 text-muted-foreground", {
                "text-primary": value === "center",
              })}
            />
          </ButtonGroup.Button>
          <ButtonGroup.Button onClick={() => onChange("end")}>
            <AlignEndVertical
              className={clsx("size-5 text-muted-foreground", {
                "text-primary": value === "end",
              })}
            />
          </ButtonGroup.Button>
        </ButtonGroup>
      </StylesAccordionItem>
    </StylesAccordion>
  );
};

export const HorizontalAlignButtonGroup = ({
  value,
  onChange,
  label = "Align Title",
}: {
  value: "start" | "center" | "end";
  onChange: (value: "start" | "center" | "end") => void;
  label?: string;
}) => {
  return (
    <StylesAccordion>
      <StylesAccordionItem label={label}>
        <ButtonGroup>
          <ButtonGroup.Button onClick={() => onChange("start")}>
            <AlignStartHorizontal
              className={clsx("size-5 text-muted-foreground", {
                "text-primary": value === "start",
              })}
            />
          </ButtonGroup.Button>
          <ButtonGroup.Button onClick={() => onChange("center")}>
            <AlignCenterHorizontal
              className={clsx("size-5 text-muted-foreground", {
                "text-primary": value === "center",
              })}
            />
          </ButtonGroup.Button>
          <ButtonGroup.Button onClick={() => onChange("end")}>
            <AlignEndHorizontal
              className={clsx("size-5 text-muted-foreground", {
                "text-primary": value === "end",
              })}
            />
          </ButtonGroup.Button>
        </ButtonGroup>
      </StylesAccordionItem>
    </StylesAccordion>
  );
};

export const TitleSize = ({
  value,
  onChange,
  label = "Title Size",
}: {
  value: CellStyle["titleSize"];
  onChange: (value: CellStyle["titleSize"]) => void;
  label?: string;
}) => {
  return (
    <StylesAccordion>
      <StylesAccordionItem label={label}>
        <ButtonGroup>
          <ButtonGroup.Button onClick={() => onChange("lg")} variant={value === "lg" ? "primary" : "grouped"}>
            lg
          </ButtonGroup.Button>
          <ButtonGroup.Button onClick={() => onChange("xl")} variant={value === "xl" ? "primary" : "grouped"}>
            xl
          </ButtonGroup.Button>
          <ButtonGroup.Button onClick={() => onChange("2xl")} variant={value === "2xl" ? "primary" : "grouped"}>
            2xl
          </ButtonGroup.Button>
          <ButtonGroup.Button onClick={() => onChange("3xl")} variant={value === "3xl" ? "primary" : "grouped"}>
            3xl
          </ButtonGroup.Button>
        </ButtonGroup>
      </StylesAccordionItem>
    </StylesAccordion>
  );
};

export const BorderStyles = ({
  cellId,
  dashboardId,
  label = "Border",
}: {
  cellId: string;
  dashboardId: string;
  label?: string;
}) => {
  const [{ border }, setStyle] = useDashboardCellStyles(cellId, dashboardId, "base", "draft");
  return (
    <StylesAccordionItem label={label}>
      <BorderField
        border={border}
        onChange={(border) => {
          setStyle({ border });
        }}
      />
    </StylesAccordionItem>
  );
};

export const TitleColorStyles = ({
  cellId,
  dashboardId,
  label = "Title Color",
}: {
  cellId: string;
  dashboardId: string;
  label?: string;
}) => {
  const [{ titleColor }, setStyle] = useDashboardCellStyles(cellId, dashboardId, "base", "draft");
  return (
    <StylesAccordionItem label={label}>
      <TextColorField
        textColor={titleColor}
        onChange={(color) => {
          setStyle({ titleColor: color });
        }}
      />
    </StylesAccordionItem>
  );
};

export const DescriptionColorStyles = ({
  cellId,
  dashboardId,
  label = "Description Color",
}: {
  cellId: string;
  dashboardId: string;
  label?: string;
}) => {
  const [{ descriptionColor }, setStyle] = useDashboardCellStyles(cellId, dashboardId, "base", "draft");
  return (
    <StylesAccordionItem label={label}>
      <TextColorField
        textColor={descriptionColor}
        onChange={(color) => {
          setStyle({ descriptionColor: color });
        }}
      />
    </StylesAccordionItem>
  );
};

export const TitleDescriptionAlignStyles = ({
  cellId,
  dashboardId,
  label = "Title Description Align",
}: {
  cellId: string;
  dashboardId: string;
  label?: string;
}) => {
  const [{ vertAlignKey = "start" }, setStyles] = useDashboardCellStyles(cellId, dashboardId, "base", "draft");
  return (
    <StylesAccordionItem label={label}>
      <ButtonGroup>
        <ButtonGroup.Button onClick={() => setStyles({ vertAlignKey: "start" })}>
          <AlignStartVertical
            className={clsx("size-5 text-muted-foreground", {
              "text-primary": vertAlignKey === "start",
            })}
          />
        </ButtonGroup.Button>
        <ButtonGroup.Button onClick={() => setStyles({ vertAlignKey: "center" })}>
          <AlignCenterVertical
            className={clsx("size-5 text-muted-foreground", {
              "text-primary": vertAlignKey === "center",
            })}
          />
        </ButtonGroup.Button>
        <ButtonGroup.Button onClick={() => setStyles({ vertAlignKey: "end" })}>
          <AlignEndVertical
            className={clsx("size-5 text-muted-foreground", {
              "text-primary": vertAlignKey === "end",
            })}
          />
        </ButtonGroup.Button>
      </ButtonGroup>
    </StylesAccordionItem>
  );
};
