import { DashboardView } from "@fscrypto/domain/dashboard";
import clsx from "clsx";
import { ImageOffIcon } from "lucide-react";
import { useDashboardCellContent } from "../../../hooks/useDashboardCellContent";
import { ActiveCellListener } from "../../active-cell/active-cell-listener";
import { BackgroundContainer, BorderContainer, TitleDescription } from "../../content-elements/content-elements";

export const ImageCellContent = ({
  cellId,
  dashboardId,
  dashboardView,
  isMobile,
}: {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
  isMobile: boolean;
}) => {
  const [{ imageUrl, altText, link, opacity, fit }] = useDashboardCellContent(
    cellId,
    dashboardId,
    "image",
    dashboardView,
  );
  const alpha = (opacity ?? 100) / 100;
  return (
    <ActiveCellListener cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} isMobile={isMobile}>
      <BorderContainer cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView}>
        <BackgroundContainer cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView}>
          <div className={clsx("flex h-full w-full flex-col")}>
            <TitleDescription cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} />
            {imageUrl ? (
              <ConditionalLink link={link}>
                <ImageContainer imageUrl={imageUrl} altText={altText} cellId={cellId} alpha={alpha} fit={fit} />
              </ConditionalLink>
            ) : (
              <EmptyImage />
            )}
          </div>
        </BackgroundContainer>
      </BorderContainer>
    </ActiveCellListener>
  );
};

const ConditionalLink = ({ link, children }: { link: string | undefined; children: React.ReactNode }) => {
  if (!link) {
    return <div className="h-full w-full">{children}</div>;
  }

  return (
    <a href={formatUrl(link)} target="_blank" rel="noreferrer" className="h-full w-full">
      {children}
    </a>
  );
};

const formatUrl = (url: string) => {
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    return `http://${url}`;
  }
  return url;
};

const EmptyImage = () => (
  <div className="align-center flex h-full w-full flex-col items-center justify-center gap-y-2">
    <div className="text-muted-foreground">No Image Uploaded</div>
    <ImageOffIcon className="text-muted-foreground h-6 w-6" />
    <div className="text-muted-foreground text-xs">Upload from the Settings PanelI</div>
  </div>
);

const ImageContainer = ({
  imageUrl,
  altText,
  cellId,
  alpha,
  fit,
}: {
  imageUrl: string;
  altText?: string;
  cellId: string;
  alpha: number;
  fit?: string;
}) => {
  return (
    <img
      src={imageUrl}
      alt={altText ?? `db-image-${cellId}`}
      className={clsx("size-full overflow-hidden", {
        "object-contain": !fit || fit === "contain",
        "object-cover": fit === "cover",
        "object-fill": fit === "fill",
        "object-none": fit === "none",
        "object-scale-down": fit === "scale-down",
      })}
      style={{ opacity: alpha }}
    />
  );
};
