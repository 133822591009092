import { CustomIcon, TitleDescription } from "../../config-elements/config-elements";

export const LayoutCellOptions = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  return (
    <div className="flex h-full w-full flex-col gap-y-4 p-3 ">
      <TitleDescription cellId={cellId} dashboardId={dashboardId} />
      <CustomIcon cellId={cellId} dashboardId={dashboardId} />
    </div>
  );
};
