import { Field, Input, Label } from "@fscrypto/ui";
import { useDashboardCellContent } from "../../../hooks/useDashboardCellContent";
import { useDashboardSettings } from "../../../hooks/useDashboardSettings";

export const HeaderCellConfig = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  const [cellContent] = useDashboardCellContent(cellId, dashboardId, "header", "draft");
  const { setTitle, setDescription } = useDashboardSettings(dashboardId);
  return (
    <div className="flex h-full w-full flex-col gap-y-4 p-3 ">
      <Field>
        <Label className="text-muted-foreground">Dashboard Title</Label>
        <Input value={cellContent?.dashboardTitle} onChange={(e) => setTitle?.(e.target.value)} />
      </Field>
      <Field>
        <Label className="text-muted-foreground">Dashboard Description</Label>
        <Input value={cellContent?.dashboardDescription} onChange={(e) => setDescription?.(e.target.value)} />
      </Field>
    </div>
  );
};
