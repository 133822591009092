import type { CellLayout, DashboardView } from "@fscrypto/domain/dashboard";
import clsx from "clsx";
import isEqual from "fast-deep-equal";
import { debounce } from "lodash-es";
import {
  type MouseEvent,
  type ReactNode,
  type RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import RGL, { type Layout } from "react-grid-layout";
import { DashboardGridContainer } from "~/features/dashboard/dashboard-grid";
import { tracking } from "~/utils/tracking";
import { useDashboardEditor } from "../../hooks/useDashboardEditor";
import { CellContentRenderer } from "../cells/cell-renderer";

/** Track changes in panel order and size */
const trackLayoutChanges = (prevLayout: RGL.Layout[], newLayout: RGL.Layout[]) => {
  const sortTopToBottom = (a: RGL.Layout, b: RGL.Layout) => a.y - b.y;
  const sortLeftToRight = (a: RGL.Layout, b: RGL.Layout) => a.x - b.x;
  const mapToIds = (l: RGL.Layout) => l.i;

  const topToBottomBefore = prevLayout.sort(sortTopToBottom).map(mapToIds);
  const topToBottomAfter = newLayout.sort(sortTopToBottom).map(mapToIds);
  const leftToRightBefore = prevLayout.sort(sortLeftToRight).map(mapToIds);
  const leftToRightAfter = newLayout.sort(sortLeftToRight).map(mapToIds);

  if (!isEqual(topToBottomBefore, topToBottomAfter) || !isEqual(leftToRightBefore, leftToRightAfter)) {
    tracking("dashboard_editor_reposition_panel", "Dashboard Beta");
  } else {
    tracking("dashboard_editor_resize_panel", "Dashboard Beta");
  }
};

interface GridLayoutProps {
  layout: CellLayout[];
  setLayout: (layout: CellLayout[]) => void;
  draggable?: boolean;
  droppable?: boolean;
  layoutId: string;
  dashboardId: string;
  dashboardView: DashboardView;
  isMobile: boolean;
}

export const GridLayout = ({
  layout,
  setLayout,
  draggable,
  layoutId,
  dashboardId,
  dashboardView,
  isMobile,
}: GridLayoutProps) => {
  const { activeCellId } = useDashboardEditor({ dashboardId });
  const gridLayout = useMemo(() => dashboardLayoutItemsToGridLayout(layout, activeCellId), [layout, activeCellId]);

  const handleLayoutChange = useCallback(
    (throttleTime: number) =>
      debounce((layout: Layout[]) => {
        const sanitizedLayout = sanitizeLayout(layout);
        const sanitizedGridLayout = sanitizeLayout(gridLayout);

        if (isEqual(sanitizedLayout, sanitizedGridLayout)) return;
        setLayout(gridLayoutToDashboardLayoutItems(layout));
      }, throttleTime),
    [setLayout, gridLayout],
  );
  const handleDrag = handleLayoutChange(400);
  const handleResize = handleLayoutChange(200);

  return (
    <DashboardGridContainer>
      {(width) => {
        return (
          <>
            <RGL
              isDraggable={dashboardView === "draft" && (draggable || layout.some((l) => l.id === activeCellId))}
              isResizable={dashboardView === "draft"}
              isDroppable={true}
              isBounded={false}
              className="h-full w-full"
              cols={12}
              rowHeight={16}
              layout={gridLayout}
              width={width}
              margin={[0, 0]}
              containerPadding={[8, 8]}
              onLayoutChange={(layout) => {
                if (dashboardView !== "draft") return;
                if (layout.some((l) => l.i === "dropped_item")) {
                  return false;
                }
                const after = sanitizeLayout(layout);
                const before = sanitizeLayout(gridLayout);
                if (isEqual(after, before)) {
                  return;
                }

                trackLayoutChanges(before, after);
                setLayout(gridLayoutToDashboardLayoutItems(layout));
              }}
              onResize={(layout) => {
                handleResize(layout);
              }}
              onDrag={(layout) => {
                handleDrag(layout);
              }}
              // compactType={null}
              // preventCollision={true}
              // draggableHandle=".grid-draggable-handle"
              resizeHandles={["ne", "se", "nw", "sw", "e", "w", "n", "s"]}
              // the typing from the react-grid-layout library is incorrect so we have to cast it here
              resizeHandle={
                ((handleAxis: string, ref: RefObject<HTMLDivElement>) => (
                  <ResizeHandle handleAxis={handleAxis} innerRef={ref} dashboardView={dashboardView} />
                )) as unknown as ReactNode
              }
            >
              {gridLayout.map((cell) => {
                return (
                  <div key={cell.i} data-resizable={cell.i === activeCellId} data-cellId={cell.i}>
                    <CellContentRenderer
                      cellId={cell.i}
                      dashboardId={dashboardId}
                      dashboardView={dashboardView}
                      layoutId={layoutId}
                      isMobile={isMobile}
                    />
                  </div>
                );
              })}
            </RGL>
          </>
        );
      }}
    </DashboardGridContainer>
  );
};

export const dashboardLayoutItemsToGridLayout = (items: CellLayout[], activeCellId?: string | null): RGL.Layout[] => {
  return items.map((item) => {
    const { id, ...rest } = item;
    return {
      i: id,
      ...rest,
      static: id === "root-header",
      // only allow active cells (but not the root header) to be resizable
      isResizable: id !== "root-header" && activeCellId === id,
    };
  });
};

export const gridLayoutToDashboardLayoutItems = (layout: RGL.Layout[]): CellLayout[] => {
  return layout.map((item) => {
    const { i, ...rest } = item;
    return {
      id: i,
      ...rest,
      isResizable: undefined,
    };
  });
};

type CustomHandleProps = {
  handleAxis: string;
  innerRef: RefObject<HTMLDivElement>;
  dashboardView: DashboardView;
  onMouseDown?: (e: MouseEvent<HTMLDivElement>) => void;
};

/**
 * Renders a custom resizable handle component.
 *
 * @param {CustomHandleProps} props - The props for the custom handle component.
 * @param {string} props.handleAxis - The axis of the handle.
 * @param {RefObject<HTMLDivElement>} props.innerRef - The reference to the parent element.
 * @param {...any} props.restProps - The rest of the props for the custom handle component.
 * @return {JSX.Element} The custom resizable handle component.
 */
const ResizeHandle = (props: CustomHandleProps) => {
  const { handleAxis, innerRef, dashboardView, ...restProps } = props;
  const isHidden = useIsHidden(innerRef, dashboardView);
  const isLayoutOrTabsPanel =
    innerRef.current?.parentElement?.getAttribute("data-cellid")?.includes("layout") ||
    innerRef.current?.parentElement?.getAttribute("data-cellid")?.includes("tabs-panel");
  const shouldShow = !isHidden && (isLayoutOrTabsPanel ? handleAxis === "e" || handleAxis === "w" : true);
  return (
    <div
      ref={innerRef}
      className={clsx(`custom-resizable-handle custom-resizable-handle-${handleAxis} nullify-active-layout-listener `, {
        "border-primary bg-background size-3 rounded-full border shadow":
          handleAxis === "nw" || handleAxis === "sw" || handleAxis === "ne" || handleAxis === "se",
        "border-primary bg-background h-8 w-2 rounded-full border shadow": handleAxis === "e" || handleAxis === "w",
        "border-primary bg-background h-2 w-8 rounded-full border shadow": handleAxis === "s" || handleAxis === "n",
        hidden: !shouldShow,
      })}
      {...restProps}
      onMouseDown={(e) => {
        e.stopPropagation();
        restProps.onMouseDown?.(e);
      }}
    />
  );
};

const sanitizeLayout = (layout: RGL.Layout[]) => {
  return layout.map((l) => {
    return {
      i: l.i,
      x: l.x,
      y: l.y,
      w: l.w,
      h: l.h,
    };
  });
};

// the hidden class is set on the parent element outside of the react render cycle, this listens to those chnages to make sure they are in sync with react state
const useIsHidden = (ref: RefObject<HTMLDivElement>, dashboardView: DashboardView) => {
  const [isHidden, setIsHidden] = useState(true);
  const observerRef = useRef<MutationObserver | null>(null);

  useEffect(() => {
    if (dashboardView !== "draft" || !ref?.current) return;

    const parent = ref.current.parentElement;

    if (!parent) return;

    const updateIsHidden = () => {
      const hidden = parent.classList.contains("react-resizable-hide");
      setIsHidden(hidden);
    };

    updateIsHidden(); // Initial check

    observerRef.current = new MutationObserver(updateIsHidden);
    observerRef.current.observe(parent, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => {
      observerRef.current?.disconnect();
    };
  }, [ref, dashboardView]);

  return isHidden;
};
